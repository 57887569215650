import { Card, Col, Row, Alert } from 'antd';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logo from '@/assets/img/l_4.png';
import LoginForm, { FormBody } from '@/components/forms/LoginForm';
import { useAppDispatch } from '@/hooks/app.hook';
import { useAuth } from '@/hooks/auth.hook';
import { login } from '@/redux/actions/auth.actions';
import { loginFailure } from '@/redux/reducers/auth';
import { ErrorCode } from '@/interfaces/auth.interface';

const LoginScreen = () => {
  const { token, loading, error, errorCode } = useAuth();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  if (token) {
    return <Navigate to="/dashboard" />;
  }

  const clearError = () => {
    dispatch(loginFailure({ error: '', errorCode: undefined }));
  };

  const onFormSubmit = async ({ email, password, remember }: FormBody) => {
    if (remember) {
      localStorage.setItem('email', email);
    }
    clearError();
    dispatch(login(email, password));
  };

  const showCustomError = (error: string, errorCode: ErrorCode) =>
    `${errorCode === 401 ? t('login.invalid') : String(error)}, ${t(
      'general.errorCode',
    )}: ${errorCode}`;

  return (
    <div className="login-screen">
      <div className="cover"></div>
      <Row className="wrapper" justify="center" align="middle">
        <Col xxl={8} xl={8} lg={12} md={18} sm={18} xs={23}>
          <Card>
            <Row justify="center">
              <Col className="logo">
                <img src={logo} alt="Real state" />
              </Col>
            </Row>
            {error && (
              <Row>
                <Col span={24} className="alert-container">
                  <Alert
                    type="warning"
                    message={showCustomError(String(error), errorCode)}
                    showIcon
                  />
                </Col>
              </Row>
            )}
            <LoginForm onSubmit={onFormSubmit} loading={loading} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LoginScreen;
