import { Button, Checkbox, Form, Input, Row, Col } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export type FormBody = {
  email: string;
  password: string;
  remember: boolean;
};
export interface LoginFormProps {
  onSubmit?: (values: FormBody) => Promise<void>;
  loading?: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Form
      name="login-form"
      layout="vertical"
      initialValues={{ remember: true, email: localStorage.getItem('email') }}
      autoComplete="off"
      onFinish={onSubmit}
    >
      <Form.Item
        label={t('login.email')}
        name="email"
        rules={[{ required: true, type: 'email', whitespace: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('login.password')}
        name="password"
        rules={[{ required: true, type: 'string', whitespace: true }]}
      >
        <Input.Password />
      </Form.Item>

      <Row align="middle" justify="space-between" style={{ marginBottom: 20 }}>
        <Col>
          <Button
            type="link"
            onClick={() => {
              navigate('/forgot');
            }}
            style={{ paddingLeft: 0 }}
          >
            {t('login.forgotPassword')}
          </Button>
        </Col>
        <Col>
          <Form.Item
            name="remember"
            valuePropName="checked"
            style={{ textAlign: 'right', marginBottom: 0 }}
          >
            <Checkbox>{t('login.rememberMe')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          style={{ width: '100%' }}
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading}
        >
          {t('login.login')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
